import { Router } from '@reach/router';
import { Layout } from 'components/layout';
import { BlogItem, BlogPage } from 'modules/blog';
import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { usePrismicDocuments } from 'hooks';

const Blog: React.FC = () => {
  const { locale } = useIntl();
  const documents = usePrismicDocuments(locale, 'blog');

  return (
    <Layout isGhostNav={false}>
      <Router>
        <BlogPage path={`/${locale}/blog/`} documents={documents} />
        <BlogItem path={`/${locale}/blog/:uid`} documents={documents} />
      </Router>
    </Layout>
  );
};

export default Blog;
